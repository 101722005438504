<template>
  <div class="Finance_wrapper">
    <el-tabs v-model="currView">
      <el-tab-pane label="收支情况" name="IncomeAndExpenditure"></el-tab-pane>
      <el-tab-pane label="充值" name="TopUp"></el-tab-pane>
      <el-tab-pane label="提现" name="Withdraw"></el-tab-pane>
    </el-tabs>
    <keep-alive>
      <component :is="currView" :activityID="activityID"></component>
    </keep-alive>
  </div>
</template>

<script>
export default {
  name: "Finance", // 财务
  components: {
    IncomeAndExpenditure: () =>
      import("./FinanceChildren/IncomeAndExpenditure"),
    TopUp: () => import("./FinanceChildren/TopUp"),
    Withdraw: () => import("./FinanceChildren/Withdraw")
  },
  props: {},
  data() {
    return {
      currView: "TopUp",
      activityID:0
    };
  },
  computed: {},
  watch: {},
  methods: {},
  created() {
    console.log(this.$route.params.activityID);
    this.activityID = this.$route.params.activityID;
  },
  mounted() {}
};
</script>

<style lang="scss" scoped>
.Finance_wrapper {
}
</style>
